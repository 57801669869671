<script setup>
  import { ref, inject, watch } from 'vue'
  import { storeToRefs } from 'pinia'
  import { useField, useFormValues } from 'vee-validate'

  import { useCustomerConfigStore } from '@/stores/customer'
  import { useConstStore } from '@/stores/const'

  import { formContextInjectionKey } from '@/helpers/form'
  import {
    addressFeatureToCustomerData,
    guessAddressData,
  } from '@/helpers/apiGouv'
  import Select from '@/components/forms/Select.vue'
  import Select2 from '@/components/forms/Select2.vue'
  import Input from '@/components/forms/Input.vue'

  import CustomerSapComponent from './sap/CustomerSapComponent.vue'
  import AddressInput from './address/AddressInput.vue'
  import ZipCodeCityInput from './address/ZipCodeCityInput.vue'

  const props = defineProps({
    customer: { type: Object },
    schemaType: { type: String },
  })

  const { getFormFieldData, setFieldValue } = inject(formContextInjectionKey)
  const values = useFormValues()

  const formConfigStore = useCustomerConfigStore()
  // Options utilisées plus bas
  let civiliteOptions = ref(formConfigStore.getOptions('civilite_options'))

  const addressCompletion = formConfigStore.getOptions('address_completion')

  // Références
  // Si on change de pays on ne veut pas de complétion ce qui est différent de l'option globale
  const completeAddress = ref(addressCompletion)
  const { consts } = storeToRefs(useConstStore())
  const { schemas: formSchemas } = storeToRefs(formConfigStore)

  const onCountryChange = (countryOption) => {
    const countryData = countryOption.data
    if (countryData) {
      const value = countryData.name
      setFieldValue('country_code', countryData.code_insee)
      if (addressCompletion) {
        completeAddress.value = value === 'FRANCE'
      }
    }
  }
  const onSelectAddress = async (addressFeature) => {
    const data = await addressFeatureToCustomerData(addressFeature)
    for (const [key, value] of Object.entries(data)) {
      if (key == 'urssaf_data') {
        if (props.schemaType != 'urssaf_data') {
          continue
        } else {
          // Ici on s'assure de conserver les données urssaf existantes
          const urssaf_data = values.value.urssaf_data
          const new_value = { ...urssaf_data, ...value }
          setFieldValue('urssaf_data', new_value)
        }
      } else {
        setFieldValue(key, value)
      }
    }
  }

  watch(
    () => props.schemaType,
    async (newType) => {
      console.log(`schemaType changed ${newType} ?`)
      let currentValues = values // useFormValues()
      if (newType == 'urssaf_data') {
        console.log('  + Guessing Urssaf data')
        civiliteOptions.value = formConfigStore.getOptions(
          'sap_civilite_options'
        )

        console.log(currentValues.value)
        let raw_values = currentValues.value
        const addressInfo = await guessAddressData(
          raw_values.address,
          raw_values.zip_code,
          raw_values.city,
          raw_values.city_code
        )
        console.log(addressInfo)
        setFieldValue('city_code', addressInfo['city_code'])
        setFieldValue('urssaf_data', {
          birthplace_country_code: '99100',
          bank_account_owner: `${raw_values.civilite || ''} ${
            raw_values.lastname || ''
          } ${raw_values.firstname || ''}`,
          ...addressInfo['urssaf_data'],
        })
      } else {
        civiliteOptions.value = formConfigStore.getOptions('civilite_options')
      }
    }
  )
</script>

<template>
  <div>
    <fieldset class="deformFormFieldset">
      <legend v-if="!customer.id">Personne physique</legend>
      <legend v-else>{{ customer.label }} (Personne physique)</legend>
      <div class="row form-row">
        <div class="col-md-4">
          <Select
            v-bind="getFormFieldData('civilite')"
            :addDefault="false"
            :options="civiliteOptions"
          />
        </div>
      </div>
      <div class="row form-row">
        <div class="col-md-4" v-for="fieldName of ['lastname', 'firstname']">
          <Input v-bind="getFormFieldData(fieldName)" />
        </div>
        <div class="col-md-4">
          <Input v-bind="getFormFieldData('code')" />
        </div>
      </div>
      <h4 class="content_vertical_padding separate_top">Adresse</h4>
      <div class="row form-row">
        <div class="col-md-6">
          <Select2
            v-bind="getFormFieldData('country')"
            idKey="name"
            labelKey="name"
            :options="consts.countries"
            :modelValue="values.country"
            @select="(value) => onCountryChange(value)"
          />

          <Input name="country_code" type="hidden" />
        </div>
      </div>
      <AddressInput
        :completion="completeAddress"
        v-on:select:address="onSelectAddress"
      />
      <ZipCodeCityInput :completion="completeAddress" />
      <h4 class="content_vertical_padding separate_top">
        Informations de contact
      </h4>

      <div class="row form-row">
        <div class="col-md-12">
          <Input v-bind="getFormFieldData('email')" />
        </div>
      </div>
      <div class="row form-row">
        <div class="col-md-6">
          <Input v-bind="getFormFieldData('mobile')" />
        </div>
        <div class="col-md-6">
          <Input v-bind="getFormFieldData('phone')" />
        </div>
      </div>
      <div class="row form-row">
        <div class="col-md-6">
          <Input v-bind="getFormFieldData('fax')" />
        </div>
      </div>
      <template v-if="formConfigStore.getOptions('is_admin')">
        <h4 class="content_vertical_padding separate_top">
          Informations comptables
        </h4>

        <div class="row form-row">
          <div class="col-md-6">
            <Input v-bind="getFormFieldData('compte_cg')" />
          </div>
          <div class="col-md-6">
            <Input v-bind="getFormFieldData('compte_tiers')" />
          </div>
        </div>
      </template>
    </fieldset>
    <CustomerSapComponent v-if="'urssaf_data' in formSchemas" />
  </div>
</template>
