<script setup>
  import { onClickOutside } from '@vueuse/core'
  import { useField } from 'vee-validate'
  import { inject, ref } from 'vue'
  import { formContextInjectionKey } from '@/helpers/form'
  import { findCity } from '@/helpers/apiGouv'
  import Input from '@/components/forms/Input.vue'

  const { handleChange: cityChange } = useField('urssaf_data.birthplace_city')
  const { handleChange: cityCodeChange } = useField(
    'urssaf_data.birthplace_city_code'
  )
  const { getFormFieldData } = inject(formContextInjectionKey)

  const props = defineProps({
    completion: { type: Boolean, default: false },
  })
  // Stocke la liste des villes possibles
  let cityOptions = ref([])
  let currentQuery
  const onSelectCity = (index) => {
    const selectedCity = cityOptions.value[index]
    if (selectedCity) {
      cityChange(selectedCity.nom)
      cityCodeChange(selectedCity.code)
      cityOptions.value = []
    }
  }
  const completeCity = (searchObject) => {
    if (currentQuery) {
      clearTimeout(currentQuery)
    }
    currentQuery = setTimeout(async () => {
      cityOptions.value = await findCity(searchObject)
      if (cityOptions.value.length === 1) {
        onSelectCity(0)
      }
    }, 300)
  }
  const onChangeCity = (value) => {
    if (props.completion) {
      if (value.length >= 3) {
        completeCity({ nom: value })
      }
    }
  }
  const dropdownTarget = ref(null)
  onClickOutside(dropdownTarget, (event) => {
    cityOptions.value = []
  })
</script>
<template>
  <div class="row form-row">
    <div class="col-md-6">
      <Input
        v-bind="getFormFieldData('urssaf_data.birthplace_city')"
        @change-value="onChangeCity"
      />
      <div v-if="cityOptions" class="form-autocomplete" ref="dropdownTarget">
        <ul>
          <li v-for="(option, index) in cityOptions" :key="index">
            <a href="javascript:void(0);" @click="() => onSelectCity(index)">
              {{ option.nom }} ({{ option.codeDepartement }})
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-6" v-if="completion">
      <Input
        v-bind="
          Object.assign(getFormFieldData('urssaf_data.birthplace_city_code'), {
            required: true,
          })
        "
      />
    </div>
  </div>
</template>
